<template>
  <S2SForm title="Cashout">
    <S2SCard>
      <v-layout row wrap>
        <v-flex xs12 md6 pr-2>
          <v-text-field
            label="Amount"
            v-money="currencyOptions"
            v-model.lazy="amount"
            data-vv-name="amount"
            v-validate="'required|currency'"
            :error-messages="errors.collect('amount')"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md6>
          <v-select
            v-model="toAccountId"
            :items="bankAccounts"
            label="To Bank Account"
            item-text="name"
            item-value="id"
            data-vv-name="toAccountId"
            v-validate="'required'"
            :error-messages="errors.collect('toAccountId')"
            no-data-text="No bank account found"
          ></v-select>
        </v-flex>
        <v-flex xs12 md6 pr-2>
          <v-text-field
            label="My Reference"
            v-model.lazy="myReference"
            data-vv-name="myReference"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 class="text-xs-right">
          <v-btn
            :block="$vuetify.breakpoint.xsOnly"
            color="accent"
            @click="onCashout()"
            :loading="loading"
            >Cashout</v-btn
          >
        </v-flex>
      </v-layout>
    </S2SCard>
  </S2SForm>
</template>

<script>
  import Vue from "vue";
  import VeeValidate from "vee-validate";
  import { debounce } from "lodash";

  const VMoney = require("v-money").VMoney;

  export default Vue.extend({
    name: "Refunds",

    directives: { money: VMoney },

    data: function () {
      return {
        // Form Data
        amount: "0.00",
        toAccountId: null,
        myReference: "",

        /* Currency Input Settings */

        currencyOptions: {
          decimal: ".",
          thousands: " ",
          prefix: "R ",
          precision: 2,
          masked: false,
        },
      };
    },

    computed: {
      loading: function () {
        return this.$store.state.transactions.loading;
      },
      bankAccounts: function () {
        return this.$store.getters["accounts/bankAccounts"];
      },
      shopAccount: function () {
        return this.$store.getters["accounts/shopAccount"];
      },
    },

    methods: {
      async onCashout() {
        if (!this.toAccountId) return;
        if (await this.$validator.validateAll()) {
          this.$store.dispatch("transactions/doCashout", {
            txnType: "transfer",
            transactingOrgId: this.$store.state.shops.activeShop.id,
            from: {
              account: {
                id: +this.shopAccount.id,
                amount: Number(this.amount.replace(/[A-Za-z\s]/g, "")),
              },
            },
            to: {
              account: {
                id: +this.toAccountId,
              },
            },
            transactorReference: this.myReference,
            sourceReference: this.myReference,
          });
          this.resetFields(); //We might want to do this. Will wait for feedback
        }
      },
      resetFields() {
        this.amount = "0.00";
        this.toAccountId = null;
        this.myReference = "";

        this.$validator.reset();
      },
      validateDictionary() {
        return {
          dictionary: {
            attributes: {
              amount: "Cashout Amount",
              toAccountId: "Cashout Bank Account",
            },
          },
        };
      },
    },

    created: function () {
      this.$validator.localize("en", this.validateDictionary().dictionary);
      this.$validator.extend("currency", {
        getMessage(field, val) {
          return "Min amount must R 0.01";
        },
        validate(value, field) {
          return Number(value.replace(/[A-Za-z\s]/g, "")) > 0;
        },
      });
    },

    mounted: function () {
      this.$store.dispatch("accounts/getAccounts");
    },
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
